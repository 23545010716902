<template>
  <DefaultModal
    ref="modal"
    :pending="pending"
    @formSubmitted="updatePassword"
    title="Zmiana hasła"
    activator-name="Zmień hasło"
  >
    <PasswordInput
      outlined
      dense
      label="Stare hasło"
      :rules="[...$rules.required]"
      v-model="oldPassword"
    />
    <RepeatPassword ref="repeatPasswordForm" />
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/Modals/DefaultModal'
import RepeatPassword from '@/components/UI/RepeatPassword'
import PasswordInput from '@/components/UI/PasswordInput'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import {mapActions} from 'vuex'

const UserProfileRepository = RepositoryFactory.get('profile')

export default {
  name: 'ChangePasswordModal',
  components: {
    PasswordInput,
    RepeatPassword,
    DefaultModal,
  },
  data() {
    return {
      oldPassword: null,
      pending: false,
    }
  },
  methods: {
    ...mapActions(['showNotification']),
    async updatePassword() {
      const passwords = await this.$refs.repeatPasswordForm.validate()
      if (passwords) {
        try {
          this.pending = true
          const payload = {
            oldPassword: this.oldPassword,
            newPassword: passwords.password,
            newPasswordConfirmation: passwords.passwordConfirmation,
          }

          await UserProfileRepository.updatePassword(payload)
          this.pending = false
          this.showNotification({
            message: 'PASSWORD_CHANGED',
            type: 'success',
          })
          this.$refs.modal.close()
        } catch (e) {
          this.pending = false
        }
      }
    },
  },
}
</script>

<style scoped></style>
