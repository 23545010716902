<template>
  <v-card>
    <v-form ref="form" lazy-validation>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <ChangePasswordModal></ChangePasswordModal>
            </v-col>
            <v-col cols="12">
              <v-text-field
                hide-details
                @keydown.space.prevent
                v-model.trim="userForm.email"
                dense
                :rules="[...$rules.emailRequired, ...$rules.maxLength(100)]"
                type="email"
                label="E-mail"
                name="email"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :rules="[...$rules.required, ...$rules.maxLength(70)]"
                v-model.trim="userForm.firstName"
                dense
                type="text"
                label="Imię"
                name="firstName"
                outlined
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                hide-details
                :rules="[...$rules.required, ...$rules.maxLength(70)]"
                v-model.trim="userForm.lastName"
                dense
                type="text"
                label="Nazwisko"
                name="lastName"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h4 class="text-h5 my-6">Ustawienia powiadomień</h4>
              <v-divider />
              <v-switch v-model="userForm.biddingNotifications" inset label="Licytacja" />
              <v-divider />
              <v-switch v-model="userForm.senutoNotifications" inset label="Raport Senuto" />
              <v-divider />
              <v-switch
                v-model="userForm.searchNodeNotifications"
                inset
                label="Raport SearchNode"
              />
              <v-divider />
              <v-switch v-model="userForm.exactagNotifications" inset label="Raport Exactag" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-form>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="save" :loading="loading">Zapisz zmiany</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import ChangePasswordModal from '@/components/Modals/ChangePasswordModal'

export default {
  name: 'Profile',
  components: {ChangePasswordModal},
  data() {
    return {
      loading: false,
      email: '',
    }
  },
  computed: {
    ...mapState({
      userForm: state => state.userProfile.editForm,
      userData: state => state.userProfile.userData,
    }),
  },
  beforeDestroy() {
    this.SET_USER_FORM(this.userData)
  },
  methods: {
    ...mapMutations(['SET_USER_FORM']),
    ...mapActions(['updateProfile', 'showNotification']),
    async save() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.updateProfile(this.userForm)
          this.showNotification({
            type: 'success',
            message: 'NOTY_USER_PROFILE_UPDATED',
          })
          await this.$router.push({name: 'Dashboard'})
        } catch (e) {
          this.showNotification({
            type: 'error',
            message: e.response.data,
          })
        }
      }
      this.loading = false
    },
  },
}
</script>

<style scoped></style>
